<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="outer-block">
      <div class="tab-titles">
        <div class="tab-options">
          <div class="d-flex gap-closure-header-sub-con">
            <div class="in-action">
              <div class="primary-color tab-option-text" :class="{ 'tab-active-option': activeTab === 'practice' }"
                @click="tabSwitch(1)">Practice Documents</div>
              <hr class="active-tab-scroll-hr" v-if="activeTab === 'practice'" />
            </div>
          </div>
          <div class="d-flex gap-closure-header-sub-con">
            <div class="in-action">
              <div class="primary-color tab-option-text" :class="{ 'tab-active-option': activeTab === 'gapClosure' }"
                @click="tabSwitch(2)">Gap Closure Archive</div>
              <hr class="active-tab-scroll-hr" v-if="activeTab === 'gapClosure'" />
            </div>
          </div>
        </div>

      </div>
      <hr class="hr" />
      <div v-if="activeTab === 'practice'">
        <div class="asmt-con">
          <div class="asmt-info">
            <div class="level-con">
              <div class="level-border">
                <b-form-radio-group id="radio-group-1" v-model="selectedPillar" name="radio-options"
                  style="display: inline-flex; width: 100%">
                  <div class="page-heading-tab level-info level-br live-level"
                    v-for="(pillar, index) in payLoadPillarList_PO" :key="pillar.pillarId" :id="pillar.pillarId" :class="{
                        cursorNotAllowed: pillar.active === 'N',
                        'start-cell': index === 0,
                        'end-cell': index === payLoadPillarList_PO.length - 1
                      }" :disabled="pillar.active === 'N'">
                    <label class="container" :class="{
                        'padding-left-center': Number(pillarId) === Number(pillar.pillarId)
                      }">
                      {{ pillar.pillarCode }}
                      <input type="radio" class="cursorpointer" name="some-radios" :value="pillar.id"
                        style="margin-right: 1rem" :disabled="pillar.active === 'N'"
                        @change="(pillarId = pillar.pillarId), handleRadioChange(pillar.pillarId)" />
                    </label>
                  </div>
                </b-form-radio-group>
              </div>
            </div>
          </div>
          <div class="asmt-info">
            <div class="level-con" style="padding-bottom: 0; padding-left: 2.7rem; padding-right: 3rem">
              <label class="page-heading-tab">Other Documents</label>
              <label for="file-upload-other-documents" :class="{
                  cursorNotAllowed: !checkPersonaForUpload,
                  'disasbled-label': !checkPersonaForUpload
                }" class="back-btn btn-action">
                Upload Document
              </label>
              <input :disabled="!checkPersonaForUpload" id="file-upload-other-documents" type="file" class="cursorpointer"
                placeholder="NO" @change="(e) => onChangeListener(e.target, 'Other')" />
            </div>
          </div>
          <div class="asmt-info">
            <div class="level-con" style="padding-top: 0; margin-top: 0">
              <b-table v-if="otherItems.length > 0" id="other-documents" class="action-home-table" responsive hover
                :items="otherItems" :fields="otherFields">
                <template #cell(documentName)="row">
                  <div>
                    {{ row.item.documentName }}
                  </div>
                </template>
                <template #head(uploadedDate)="data">
                  <div class="dateCol">{{ data.label }}</div>
                </template>
                <template #head(uploadedBy)="data">
                  <div class="textCol">{{ data.label }}</div>
                </template>
                <template #cell(uploadedDate)="row">
                  <div class="dateCol">
                    {{ row.item.uploadedDate | dateFormater }}
                  </div>
                </template>
                <template #cell(uploadedBy)="row">
                  <div class="textCol">
                    {{ row.item.uploadedBy }}
                  </div>
                </template>

                <template #cell(download)="row">
                  <ul class="heightDownload uploaded-items">
                    <li @click="downloadItem(row.item)" class="uploaded-items-li doc-bt back-btn btn-action"
                      :key="row.item.poOtherDocumentsId">
                      <span class="uploaded-file-name">
                        <a class="li-doc-class"> Download </a>
                      </span>
                    </li>
                  </ul>
                </template>
                <template #cell(delete)="row">
                  <b-button v-b-modal.warningPopup @click="toDeleteRowData = {
                      documentId: row.item.poOtherDocumentsId,
                      docReference: 'Other'
                    }
                    " pill :class="{
      cursorNotAllowed: !checkPersonaForUpload
    }" :disabled="!checkPersonaForUpload" variant="outline-secondary"
                    class="heightDelete doc-bt primary-color page-heading-tab status-btn">
                    Delete
                  </b-button>
                </template>
              </b-table>
              <div v-if="!otherItems.length" class="no-data">
                <img alt="no data" src="../../../assets/No_Data.svg" />
                <span class="ed-date">No Records Found</span>
              </div>
            </div>
          </div>
          <div class="asmt-info">
            <div id="principle-documents" class="widthHundred">
              <div v-for="(principle, i) in principleData" :key="(principle, i)" class="cursorpointer">
                <div class="" style="margin-left: 2rem; margin-right: 2rem">
                  <div class="row level-row"
                    style="justify-content: space-between; padding-top: 0.5rem; padding-bottom: 0.3rem; margin-bottom: 0.5rem">
                    <div class="row" style="padding-left: 15px; width: 90%; display: inline-flex" @click="() => {
                        if (principleId === principle.principleId) principleId = null;
                        else principleId = principle.principleId;
                      }
                      ">
                      <div style="padding-right: 1rem; padding-top: 0.2rem">
                        <img alt="up" v-if="principleId == principle.principleId" src="@/assets/gov-up.svg"
                          @click="principleId = null" />
                        <img alt="down" v-else src="@/assets/gov-down.svg" @click="principleId = principle.principleId" />
                      </div>
                      <div class="ellipse" :value="principle.principleNumber">
                        <label class="ellipse-text">
                          <strong>{{ principle.principleNumber }}</strong>
                        </label>
                      </div>
                      <div class="level-status target-card-lable principle-name-label" :value="principle.principleName"
                        style="padding-left: 10px">
                        {{ principle.principleName }}
                      </div>
                    </div>
                    <div style="margin-right: 10px">
                      <b-button :class="{ cursorNotAllowed: !checkPersonaForUpload }" :disabled="!checkPersonaForUpload"
                        id="editButton" style="font-family: 'BarlowM', sans-serif"
                        class="rounded-pill back-btn btn-action" pill variant="primary" v-b-modal.principleDocuments
                        @click="principleId = principle.principleId">Upload Document
                      </b-button>
                      <div style="margin-right: 10px"></div>
                    </div>
                  </div>
                  <template>
                    <div class="view-asmt border border-0" style="margin-top: -0.5rem"
                      v-if="principleId === principle.principleId">
                      <b-table v-if="principle.documentData.length" id="principle-document" class="" responsive hover
                        :items="principle.documentData" :fields="principleFields">
                        <template #cell(practice)="row">
                          <div>
                            {{ principle.principleNumber + "." + row.item.levelId }}
                          </div>
                        </template>
                        <template #cell(documentName)="row">
                          <div>
                            {{ row.item.docName }}
                          </div>
                        </template>
                        <template #head(uploadedDate)="data">
                          <div class="dateCol">{{ data.label }}</div>
                        </template>
                        <template #head(uploadedBy)="data">
                          <div class="textCol">{{ data.label }}</div>
                        </template>
                        <template #cell(uploadedDate)="row">
                          <div class="dateCol">
                            {{ row.item.updatedTimestamp | dateFormater }}
                          </div>
                        </template>
                        <template #cell(uploadedBy)="row">
                          <div class="textCol">
                            {{ row.item.uploadedBy }}
                          </div>
                        </template>

                        <template #cell(download)="row">
                          <ul class="heightDownload uploaded-items">
                            <li @click="downloadItem(row.item)" class="uploaded-items-li doc-bt back-btn btn-action"
                              :key="row.item.docId">
                              <span class="uploaded-file-name">
                                <a class="li-doc-class">Download</a>
                              </span>
                            </li>
                          </ul>
                        </template>
                        <template #cell(delete)="row">
                          <b-button :class="{
                              cursorNotAllowed: !checkPersonaForUpload
                            }" :disabled="!checkPersonaForUpload" v-b-modal.warningPopup @click="toDeleteRowData = {
      documentId: row.item.docId,
      docReference: row.item.docReference
    }
    " pill variant="outline-secondary" class="heightDelete doc-bt page-heading-tab status-btn">
                            Delete
                          </b-button>
                        </template>
                      </b-table>
                      <div v-if="!principle.documentData.length" class="no-data">
                        <img alt="no data" src="../../../assets/No_Data.svg" />
                        <span class="ed-date">No Records Found</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <!---for loop end --->
          </div>
          <b-modal id="principleDocuments" ref="principleDocuments">
            <template #modal-header="{ close }">
              <p class="pop-up-title">Select level for upload</p>
              <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
            </template>
            <template #default="{}">
              <label>Please select level for upload document</label>
              <div class="marginTop_popup">
                <label for="levelSelect" class="form-label select-lable lable-text">Level</label>
                <select class="form-control page-heading-dropdown-content principal-selecct cust-st-icon" id="levelSelect"
                  v-model="levelSelected" @change="onChangeLevelSelect($event.target.value)">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
                <label class="validation-message-style">{{ fileErrorMessage }}</label>
              </div>
            </template>
            <template #modal-footer="{}">
              <label for="file-upload-principle-documents" class="back-btn btn-action"> Browse & upload </label>
              <input id="file-upload-principle-documents" type="file" placeholder="NO" @change="(e) => {
                  onChangeListener(e.target, 'Principle');
                }
                " />
            </template>
          </b-modal>

          <b-modal id="warningPopup" ref="warningPopup">
            <template #modal-header="{ close }">
              <p class="pop-up-title">Warning</p>
              <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
            </template>
            <template #default="{}">
              <label> Are you sure, you want to delete? </label>
            </template>
            <template #modal-footer="{}">
              <label for="file-upload-principle-documents" class="discard-btn btn-action close-modal"
                @click="$refs['warningPopup'].hide()"> Discard </label>
              <label for="file-upload-principle-documents" class="back-btn btn-action"
                @click="deletePrinciple(toDeleteRowData)"> Delete </label>
            </template>
          </b-modal>
        </div>
      </div>
      <div v-else-if="activeTab === 'gapClosure'">
        <GapClosureArchive :startLoader="startLoader" :stopLoader="stopLoader" />
      </div>
    </div>
    <message-popup :headText="''" :message="errorMsg" :routToBack="false" :show="errorShow" />
  </div>
</template>
<script>
import Loader from "../../../components/common/Loader.vue";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import ApiCalls from "../../../common/api/apiCalls";
import Api from "../../../common/constants/api";
import Role from "../../../common/constants/roles";
import axios from "axios";

import GapClosureArchive from "./GapClosureArchive.vue";
import MessagePopup from '@/components/common/MessagePopup.vue';

export default {
  name: "ManagePODocument",
  data() {
    return {
      errorMsg: '',
      errorShow: false,
      roles: Role,
      fileErrorMessage: "",
      selectedPillar: 1,
      principleData: [],
      showLoader: false,
      activeTab: "practice",
      pillarId: "1",
      principleId: 1,
      levelSelected: "0",
      toDeleteRowData: {},
      payLoadPillarList_PO: [],
      payLoadPillarList: [
        {
          labelName: "PEC",
          id: 1
        },
        {
          labelName: "ZLC",
          id: 3
        },
        {
          labelName: "WPO",
          id: 4
        },
        {
          labelName: "DEC",
          id: 5
        },
        {
          labelName: "CSD",
          id: 6
        },
        {
          labelName: "RE",
          id: 7
        },
        {
          labelName: "FI",
          id: 8
        },
        {
          labelName: "PF",
          id: 10
        },
        {
          labelName: "EEM",
          id: 11
        },
        {
          labelName: "NPI",
          id: 12
        },
        {
          labelName: "LPO",
          id: 13
        },
        {
          labelName: "SA",
          id: 14
        }
      ],
      otherItems: [],
      otherFields: [
        {
          key: "documentName",
          label: "Document Name",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "uploadedDate",
          label: "Uploaded Date",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "uploadedBy",
          label: "Uploaded By",
          tdClass: "border border-0",
          sortable: false
        },

        {
          key: "download",
          label: "",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "delete",
          label: "",
          tdClass: "border border-0",
          sortable: false
        }
      ],
      principleFields: [
        {
          key: "practice",
          label: "Practice",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "documentName",
          label: "Document Name",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "uploadedDate",
          label: "Uploaded Date",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "uploadedBy",
          label: "Uploaded By",
          tdClass: "border border-0",
          sortable: false
        },

        {
          key: "download",
          label: "",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "delete",
          label: "",
          tdClass: "border border-0",
          sortable: false
        }
      ]
    };
  },
  components: {
    Loader,
    Breadcrumb,
    GapClosureArchive,
    MessagePopup
  },
  computed: {
    checkPersonaForUpload() {
      return !!this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER);
    }
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 7
    });
    this.$store.dispatch("breadcrumb", {
      title: "Reference Documents",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Education / ", primary: false },
        { name: "Manage PO Documents", primary: false }
      ]
    });
  },
  created() {
    this.showLoader = true;
    this.pillarList();
  },
  mounted() {
    setTimeout(() => {
      this.handleRadioChange("1");
      this.getPrincipleDocuments();
    }, 3000);
  },
  methods: {
    tabSwitch(pos) {
      switch (pos) {
        case 1:
          this.activeTab = "practice";
          this.showLoader = true;
          break;
        case 2:
          this.activeTab = "gapClosure";
          this.showLoader = true;
          break;

        default:
          break;
      }

    },
    startLoader() {
      console.log("start")
      this.showLoader = true
    },
    stopLoader() {
      console.log("stop")
      this.showLoader = false
    },
    async downloadItem(obj) {
      const url = JSON.parse(JSON.stringify(obj.documentUrl));
      const label = JSON.parse(JSON.stringify(obj.documentName));
      const response = await axios.get(url, { responseType: "blob" });
      // apiCalls.httpCallDetails(url, "get").then((response) => {
      console.log(response);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // })
    },
    pillarList() {
      ApiCalls.httpCallDetails(Api.GETPILLARDROPDOWNLIST, "get").then((dataJSON) => {
        this.payLoadPillarList_PO = dataJSON.data;
        setTimeout(() => {
          this.handleRadioChange("1");
          this.getOtherDocuments();
          this.getPrincipleDocuments();
        }, 3000);
      });
    },
    onChangeLevelSelect(value) {
      this.levelSelected = value;
    },
    deleteOtherDocument(details) {

      ApiCalls.httpCallDetails(Api.DELETEPODOCUMENT, "put", details).then((dataJSON) => this.getOtherDocuments());
    },

    deletePrinciple(details) {
      ApiCalls.httpCallDetails(Api.DELETEPODOCUMENT, "put", details).then((dataJSON) => {
        this.$refs["warningPopup"].hide();
        this.getPrincipleDocuments();
        this.getOtherDocuments();
      });
    },
    onChangeListener(value, from) {
      this.errorMsg = ""
      console.log("1")
      this.errorShow = false
      if (value.files[0]) {
        this.showLoader = true;
        let reader = new FileReader();
        console.log("2")
        reader.readAsDataURL(value.files[0]);
        reader.onloadend = () => {
          console.log("3", (value.files[0].size))
          const maxAllowedSize = 10 * 1024 * 1024;
          if (value.files[0].size > maxAllowedSize) {
            this.errorMsg = "Please upload file less than 10MB"
            this.errorShow = true
            this.fileErrorMessage = "Please upload file less than 10MB";
            console.log("4")
            this.showLoader = false;
            document.getElementById("file-upload-other-documents").value = null
          } else {
            this.fileErrorMessage = "";
            let imageSet = [];
            imageSet.push(reader.result);
            let newImageSet = [];
            newImageSet.push(value.files[0]);
            let timeStamp = new Date().toISOString();
            let str = value.files[0].name;
            let nameFormed = "";
            for (let itr = 0; itr <= value.files[0].name.split(".").length - 2; itr++) {
              if (nameFormed === "") {
                nameFormed = value.files[0].name.split(".")[itr];
              } else {
                nameFormed = nameFormed + "." + value.files[0].name.split(".")[itr];
              }
            }
            let type = value.files[0].name.split(".")[value.files[0].name.split(".").length - 1];
            let fileName = str.substring(str.lastIndexOf("."), 0) + "_$^#^$_" + timeStamp + "_$^#^$_." + type;
            let fileUploadFormData = new FormData();
            fileUploadFormData.append("imgsrc", newImageSet[0], fileName);
            ApiCalls.httpCallDetails(Api.CREATEDOCUMENT, "post", fileUploadFormData)
              .then((data) => {
                let name = value.files[0].name;
                console.log(name);
                let req = {};
                if (from === "Other") {
                  req = {
                    pillarId: this.pillarId,
                    documentUrl: data.message,
                    documentType: type,
                    documentName: nameFormed
                  };
                  ApiCalls.httpCallDetails(Api.ADDOTHERPODOCUMENT, "post", req)
                    .then((dataJSON) => {
                      this.showLoader = false;
                      this.getOtherDocuments();
                    })
                    .catch((e) => {
                      this.showLoader = false;
                      this.$refs["principleDocuments"].hide();
                    });
                } else {
                  req = {
                    principleId: this.principleId,
                    levelId: this.levelSelected,
                    documentUrl: data.message,
                    documentType: type,
                    documentName: nameFormed
                  };

                  ApiCalls.httpCallDetails(Api.ADDPRACTICEPODOCUMENT, "post", req)
                    .then((dataJSON) => {
                      this.showLoader = false;
                      this.getPrincipleDocuments();
                      this.$refs["principleDocuments"].hide();
                    })
                    .catch((e) => {
                      this.showLoader = false;
                      this.$refs["principleDocuments"].hide();
                    });
                }
              })
              .catch((e) => {
                this.showLoader = false;
                this.$refs["principleDocuments"].hide();
              });
          }
        };
      }
      if (from === "other") {
        // TODO document why this block is empty
      }
    },
    getPrincipleDocuments() {
      this.principleData = [];
      ApiCalls.httpCallDetails(Api.GETALLPRACTICEDOCUMENTSBYPRINCIPLE_PO + this.pillarId, "get").then((dataJSON) => {
        let data = dataJSON.data;
        this.principleData = data;
        this.showLoader = false;
        //console.log("this.principleData",this.principleData)
        this.principleData = this.principleData.sort((a, b) => {
          if (a.principleNumber !== b.principleNumber) return a.principleNumber < b.principleNumber ? -1 : 1;
          else return 0;
        });
        this.principleData = this.principleData.filter((val) => {
          return val.documentData.sort((a, b) => {
            if (a.levelId !== b.levelId) return a.levelId < b.levelId ? -1 : 1;
            else return 0;
          });
        });
      });
    },
    getOtherDocuments() {
      ApiCalls.httpCallDetails(Api.GETOTHERDOCUMENTS_PO + this.pillarId, "get").then((dataJSON) => {
        this.otherItems = [];
        let data = dataJSON.data;
        for (const element of data) {
          this.otherItems.push({
            documentName: element.documentName,
            uploadedDate: element.updatedTimestamp,
            uploadedBy: element.uploadedByUser,
            download: element.documentUrl,
            delete: element.poOtherDocumentsId,
            poOtherDocumentsId: element.poOtherDocumentsId,
            documentUrl: element.documentUrl,
            documentType: element.documentType
          });
        }
      });
    },
    handleRadioChange(event) {
      for (const element of this.payLoadPillarList_PO) {
        let radio = document.getElementById("" + element.pillarId);
        if (radio) {
          radio.classList.remove("live-level");
          radio.classList.add("live-level");
        }
        // }
      }
      for (const element of this.payLoadPillarList_PO) {
        let radio = document.getElementById("" + element.pillarId);
        if (radio) {
          radio.classList.remove("active-level");
        }
      }
      let radioElement = document.getElementById("" + event);
      this.pillarId = event;
      if (radioElement) {
        radioElement.classList.add("active-level");
      }
      this.getOtherDocuments();
      this.getPrincipleDocuments();
    }
  }
};
</script>
<style scoped>
/* tabs */

.tab-titles {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-options {
  display: flex;
  margin-top: 2rem;
}

.tab-option-text {
  font: 1.16rem "BarlowR", sans-serif;
  cursor: pointer;
}

.in-action {
  margin-right: 1.688em;
}

.tab-active-option {
  font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
  border-top: 3px #274ba7 solid;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  margin-top: 1rem;
}

.select-dd {
  width: 10%;
}

.button-dd {
  display: inline-flex;
}

.outer-block {
  background-color: #fff;
  border-radius: 6px;
}

.hr {
  position: relative;
  top: -26px;
}

/* tabs */

.padding-left-center {
  padding-left: 22%;
}

.disasbled-label {
  opacity: 0.65;
}

.cursorNotAllowed {
  cursor: not-allowed;
  opacity: 0.3;
}

.active-level {
  background: #00428f !important;
  color: #fff;
}

.active-level:hover {
  background: #d9dff3 !important;
  color: #fff;
}

.active-level:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  width: 0;
  height: 0;
  border-top: solid 10px #00428f;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.active-level:hover:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #d9dff3;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.menu-body {
  padding-top: 4rem !important;
}

.marginTop_popup {
  margin-top: 10px;
}

.pop-up-title {
  color: #234372;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
}

.principal-selecct {
  height: 54px;
  border-color: #313131;
}

.lable-text {
  position: absolute;
  background: #fff;
  margin: -1.2rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}

.textCol {
  text-align: left;
}

.dateCol {
  text-align: start;
  float: left;
}

.ellipse {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background: #687b9b 0% 0% no-repeat padding-box;

  padding: 4px;
}

.ellipse-text {
  vertical-align: middle;
  font-family: "BarlowR", sans-serif;
  font-size: 1.3rem;
  color: #ffffff;
  line-height: 1.6666666666666667rem;
}

.widthHundred {
  width: 100%;
}

input[type="file"] {
  display: none;
}

.uploaded-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.uploaded-items-li {
  display: flex;
  align-items: center;
}

.li-doc-class {
  color: #274ba7 !important;
  text-decoration: none;
}

.status-btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.heightDownload {
  height: 3rem;
}

.heightDelete {
  height: 3rem;
}

.btn-action {
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.doc-bt {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.doc-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.discard-btn {
  background: transparent;
  border-color: transparent;
  padding: 0.6rem 1.2rem;
  color: #274ba7;
  float: right;
  border-radius: 2rem;
}

.back-btn {
  background: #274ba7;
  border-color: #274ba7;
  padding: 0.6rem 1.2rem;
  color: white;
  float: right;
  border-radius: 2rem;
}

.back-btn :hover {
  border-color: #6e92ec !important;

}

.back-btn :active {
  border-color: #6e92ec !important;

}

.level-con {
  padding: 1rem 2rem 2rem 2rem;
  cursor: pointer;
  width: 100%;
}

.level-row {
  text-align: center;
  background: #eff3ff;
  border: 1px solid #ebecef;
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.level-status {
  color: #454545;
}

.principle-name-label {
  margin-top: 5px;
  vertical-align: middle;
  font-size: 1.33rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
}

.level-info {
  width: 17%;
  text-align: center;
  height: 64px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-border {
  display: flex;
  border: 1px solid #dedbdb;
  border-radius: 6px;
}

.asmt-con {
  background: #fff;
  border-radius: 6px;
  padding-bottom: 1rem;
}

.asmt-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.borderBottom {
  border-bottom: 1px solid #dedbdb;
}

.level-br {
  border-right: 1px solid #dedbdb;
}

.cursorpointer {
  cursor: pointer;
}

/* .active-level {
  background: #006400 !important;
  color: #fff;
}
.active-level:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  width: 0;
  height: 0;
  border-top: solid 10px #006400;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
} */
.live-level {
  background: #eff3ff;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container-active {
  padding-left: 3rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
}

.start-cell {
  border-radius: 4px 0 0 4px;
}

.end-cell {
  border-radius: 0px 4px 4px 0;
}
</style>
