<template>
    <!-- <div class="menu-body">  
        <Loader v-if="showLoader" />
        <Breadcrumb /> -->
    <div class="main-con">
        <div class="mat-info-con even-space-vertical-align">
            <div class="plant-dd" style="width: 100%; display: flex">
                <div class="plant-dd">
                    <label for="committed_year" class="form-label select-lable lable-text"> Year </label>
                    <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                        v-model="committedYr" @change="filterHandler()">
                        <option v-for="year in committedYear" v-bind:key="year" :value="year">
                            {{ year }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div style="display: flex; flex-wrap: wrap">
            <div class="machine-action-tracker">
                <b-table id="history-table-id" class="gn-table-r" responsive :items="historyList"
                    :fields="historyListHeader">

                    <template #cell(updatedDate)="updatedDate">
                        {{ dateFormat(updatedDate) }} EST
                    </template>
                    <template #cell(filePath)="filePath">
                        <!--  :href="filePath.value" -->
                        <a class="li-doc-class" @click="downloadItem(filePath)">
                            <img alt="download" src="../../../assets/download_icon.svg" />
                        </a>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
    <!-- </div> -->
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import Roles from "@/common/constants/roles";
import moment from "moment";
import axios from 'axios';

export default {
    name: "ActionTrackerPillarWise",
    props: { startLoader: Function, stopLoader: Function },
    data() {
        return {
            resData: [],
            pilladDtl: [],
            pilladDtl1: [],
            plantList: [],
            pillarList: [],
            workCenterList: [],
            disableTargetbtn: false,
            plantId: 0,
            committedYear: [],
            machineScore: [],
            committedYr: new Date().getFullYear(),
            noMachineDataFound: false,
            isTargetSetandApproved: true,
            historyListHeader: [
                {
                    key: "sno",
                    label: "SNo.",
                    sortable: false
                },
                {
                    key: "fileName",
                    label: "File name",
                    sortable: false
                },
                {
                    key: "updatedDate",
                    label: "Upload Date",
                    sortable: false
                },
                {
                    key: "updatedBy",
                    label: "Upload By",
                    sortable: false
                },
                {
                    key: "filePath",
                    label: "",
                    sortable: false
                }
            ],
            historyList: [],
            loggedInRole: "",
            roles: Roles
        };
    },
    components: {
        Breadcrumb,
        Loader
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 7
        });
        this.$store.dispatch("breadcrumb", {
            title: "Reference Documents",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Education / ", primary: false },
                { name: "Manage PO Documents", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.filterHandler();
        this.loggedInRole = this.$oidc.userProfile.roles[0];
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "prevSelectedWorkCenter", "loggedInUserData", "pillarPrevSelected"])
    },
    methods: {
        dateFormat(updatedDate) {
            return moment(updatedDate.value).format('MMM-DD-YYYY, hh:mm a')
        },
        async downloadItem(obj) {
            this.startLoader()
            const url = obj.value;
            const label = obj.item.fileName + moment(obj.item.updatedDate).format('MMM-DD-YYYY')
            console.log(url)
            const response = await axios.get(url, { responseType: "blob" });
            console.log(response);
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label;
            link.click();
            URL.revokeObjectURL(link.href);

            this.stopLoader()

        },
        filterHandler() {

            ApiCalls.httpCallDetails(Api.GAPCLOUSREARCHIVE, "get").then((data) => {
                this.rawHistoryList = []
                this.stopLoader()
                data.data.forEach(row => {
                    this.committedYear.push(row.year);
                    this.rawHistoryList = [...this.rawHistoryList, ...row.maGapClosureUploads]
                    this.rawHistoryList.forEach((upload, index) => {
                        upload['year'] = row.year
                        upload['sno'] = index + 1
                    })
                });
                this.committedYr = new Date().getFullYear();
                this.historyList = this.rawHistoryList.filter(row => row.year === this.committedYr)
                this.historyList.forEach((upload, index) => {
                    upload['sno'] = index + 1
                })
                console.log(this.rawHistoryList)
            });
        }
    }
};
</script>

<style scoped>
.graph-div {
    width: 25%;
    padding: 0 1%;
}

.main-con {
    background: #fff;
    margin-top: 0 !important;
    border-radius: 6px;
}

.mat-info-con {
    padding: 0 2rem !important;
    display: flex;
}

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-status-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.machine-action-tracker {
    margin-top: 2rem;
    width: 100%;
}

#machine-action-tracker th.table-success {
    background-color: #f4f6fd;
}

#machine-action-tracker th:first-child,
#machine-action-tracker th:nth-child(2) {
    background-color: white !important;
    font-weight: medium;
}

#machine-action-tracker th:nth-child(2) {
    cursor: pointer;
}

#machine-action-tracker tr:nth-child(3n-2) td:first-child,
#machine-action-tracker tr:nth-child(3n-2) td:nth-child(2) {
    border-bottom: 1px solid #eae6e6 !important;
}

#machine-action-tracker tr:nth-child(3n) td {
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-action-tracker-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-action-table {
    max-height: 37rem !important;
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
}

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
}

#machine-action-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th:first-child {
    width: 15%;
}

.machine-action-table td,
.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}

.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table th:first-child,
.machine-action-table td:first-child {
    background-color: transparent !important;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.machine-action-table tr {
    height: 5rem;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    text-align: center !important;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    text-align: center !important;
    border-radius: 10rem;
    color: #fff !important;
}

.default {
    width: 2rem;
    background: transparent;
    margin: 0 25%;
    height: 2rem;
    text-align: center !important;
    border-radius: 10rem;
    color: #333 !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
